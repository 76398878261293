import React from 'react';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import MainContent from '../components/mainContent';
import  Jumbo from '../components/jumbo';
import Layout from '../components/Layout';
import { graphql } from 'gatsby'

export default ({ data }) => (
  <Layout>
    <HelmetDatoCms seo={data.datoCmsFacilitiesPage.seoMetaTags} />

    <Jumbo
      image={data.datoCmsFacilitiesPage.headerImage.fluid}
      title={data.datoCmsFacilitiesPage.mainHeading}
      subTitle={data.datoCmsFacilitiesPage.subHeading}
    />

    <MainContent content={data.datoCmsFacilitiesPage.mainText} />
  </Layout>
);

export const query = graphql`
  query FacilitiesPageQuery($slug: String!) {
    datoCmsFacilitiesPage(slug: { eq: $slug }) {
      mainHeading
      mainText
      subHeading
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      headerImage {
        fluid(maxWidth: 1100, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
  }
`;
